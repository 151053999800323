<template>
  <div class="products">
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Take Out') }}</h2>
      </div>
      <div class="seat-list">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th scope="col">{{ $t('Record ID') }}</th>
              <th scope="col">{{ $t('Staff Name') }}</th>
              <th scope="col">{{ $t('Status') }}</th>
              <th scope="col">{{ $t('Created Date / Time') }}</th>
              <th scope="col">{{ $t('Scheduled time') }}</th>
              <th scope="col">{{ $t('Source') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Yu Lo</td>
              <td>{{ $t('Completed')}}</td>
              <td>2023-10-27 20:00:00</td>
              <td>2023-10-27 21:00:00</td>
              <td> {{ $t('25649590')}}</td>
            </tr>
            <tr>
              <td>2</td>
              <td>YIP YI WING</td>
              <td>{{ $t('Completed')}}</td>
              <td>2024-01-02 12:30:00</td>
              <td>2024-01-02 12:50:00</td>
              <td> {{ $t('92632530')}}</td>
            </tr>
            <tr>
              <td>3</td>
              <td>蘇進邦</td>
              <td>{{ $t('Completed')}}</td>
              <td>2024-01-03 12:00:00</td>
              <td>2024-01-03 01:00:00</td>
              <td> {{ $t('61713484')}}</td>
            </tr>
            <tr>
              <td>4</td>
              <td>尹孜然</td>
              <td>{{ $t('Completed')}}</td>
              <td>2024-01-05 13:30:00</td>
              <td>2024-01-05 14:00:00</td>
              <td> {{ $t('52979344')}}</td>
            </tr>
            <tr>
              <td>5</td>
              <td>李昇霖</td>
              <td>{{ $t('Completed')}}</td>
              <td>2024-01-10 13:00:00</td>
              <td>2024-01-10 13:30:00</td>
              <td> {{ $t('61433368')}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';

export default {
  name: 'Seats',
  mounted(){
  },
  data(){
    return {
      seats: [],
    }
  },
  computed: mapState({
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.seat-image{
  margin-left: auto;
  margin-right: auto;
  max-width: 30%;
  display: block;
}
.seat-list{
  margin-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}
</style>
